import React from 'react';
import Home from './components/home/Home'
import './styles/styles.scss'

function App() {
  return (
      <Home/>
  );
}

export default App;
