import React from 'react'
import {
    Grid,
    Box,
    Hidden,
    makeStyles
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        height: 'calc(100vh - 30px)'
    },
    sectionHeader: {
        position: 'relative',
        paddingBottom: 20,
        marginBottom: 20,
        '&:after': {
            content: "''",
            position: 'absolute',
            width: 50,
            height: 5,
            backgroundColor: '#FF0000',
            bottom: 0,
            left: 0
        }
    },
    textAboveHeader: {
        color: theme.palette.grey[500],
        marginBottom: 5
    },
    sideImage: {
        width: '100%',
    }
}))

function LeftSide(props) {

    const classes = useStyles()

    return(
        <Box px={8} py={8} my={8}>
            <Box className={classes.textAboveHeader}>
                Lorem Ipsum 
            </Box>
            <Box className={classes.sectionHeader} fontSize={24} fontWeight={600}>
                We Are Creating The Future Of Cannabis Today
            </Box>
            <Box className={classes.mainText} letterSpacing={1.3} lineHeight={2}>
                Montu develops innovative cannabinoid-based products and makes them accessible to patients through a growing nationwide network of doctors.

                We are collaborating with leading cannabis cultivators and extraction companies across the world to provide the highest quality medical-grade cannabis and cannabis-based medicines through our Circle brand.

                In line with our commitment to improve the lives of millions of patients, we have quickly become a trusted partner to doctors and patients alike and have built critical infrastructure to support our mission.
            </Box>
        </Box>
    )
}

function RightSide(props){

    const classes = useStyles()

    return(
        <Box style={{
            overflow: 'hidden',
            height: '100%',
            width: '100%',
            backgroundImage: 'url(/images/dummyBG.jpeg)'
        }}>
            <Hidden mdUp>
                <img src="/images/dummyBG.jpeg" alt="img" className={classes.sideImage}/>
            </Hidden>
        </Box>
    )
}

function PageTemplate(props) {

    const [pageHeight, setPageHeight] = React.useState(window.innerHeight)

    React.useEffect(() => {
        changePageHeight()
    },[pageHeight])

    window.addEventListener('resize',() => {
        changePageHeight()
    })

    function changePageHeight(){
        setPageHeight(window.innerHeight - document.getElementById('navBar').clientHeight)
    }

    const { rightSide, leftSide, wrap, swap } = props

    return (
        <Grid container wrap={wrap} style={{
            minHeight: pageHeight
        }} >
            <Grid item md={6} sm={12} style={{ width: '100%' }}>
                {
                    leftSide ? leftSide : 
                    (
                        swap ? <RightSide/> : <LeftSide/>
                    )
                }
            </Grid>
            <Grid item md={6} sm={12}>
                {
                    rightSide ? rightSide : (
                        swap ? <LeftSide/> : <RightSide/>
                    )
                }
            </Grid>
        </Grid>
    )
}

export default PageTemplate
