import React, { Component } from 'react'
import {
    Grid,
    Box,
    Typography,
    TextField,
    Button,
    Backdrop
} from '@material-ui/core'
import mg from '../../mailgun/mailgun';
import CircularProgress from '@material-ui/core/CircularProgress';

class ContactForm extends Component {

    constructor(props){
        super(props);

        this.state = {
            fullName: "",
            jobTitle: "",
            company: "",
            email: "",
            subject: "",
            message: "",
            successMessage: "",
            openBackdrop: false
        }
    }

    onSubmit = (e) => {

        e.preventDefault()
        console.log("Sending Email")

        this.setState({ openBackdrop: true } )

        var data = {
            from: this.state.email,
            to: 'kriactivedesigns@gmail.com',
            subject: this.state.subject,
            html: this.createMessage()
        }

        var response = new Promise((resolve,reject) => {
            mg.messages().send(data, function(error, body) {
                if(error) {
                    reject(error)
                }else(
                    resolve(body)
                )
            })
        })

        
        response.then(response => {
            console.log(response)
            this.setState({fullName: "", jobTitle: "", company: "", email: "", subject: "", message: ""})
            this.setState({ successMessage: "Thanks, We will get back to you soon.." })
            document.getElementById("contactUsForm").reset();
            this.setState({ openBackdrop: false } )
        }).catch(error => {
            console.log(error)
            this.props.setSuccess("Sorry unable to send message, please try later!")
            document.getElementById("contactUsForm").reset();
            this.setState({ openBackdrop: false } )
        })

    }

    createMessage = () => {
        return (
            `<div style="width: 100%; min-height: 200px; background-color: ${this.props.theme.palette.common.lightBlue}; padding: 30px">
                <div style="margin-top: 30px; margin-bottom: 30px; height: 100%; padding: 20px; max-width: 700px; min-width: 200px; margin-left: auto; margin-right: auto; background-color: ${this.props.theme.palette.common.white};">
                    <h3 style="margin: 0 0 10px 0;">${this.state.fullName.toUpperCase()}</h3>
                    <span>${this.state.jobTitle}</span>
                    <h4 style="margin: 5px 0 10px 0;">${this.state.company}</h4>
                    <span style="font-weight: bold;">Subject:</span> <span style="font-style: italic;">${this.state.subject}</span>
                    <p>
                        ${this.state.message}
                    </p>
                </div>
            </div>`
        )
    }

    onChange = (e) => {
        this.setState({ [ e.target.name ] : e.target.value })
    }
    
    render() {
        
        const { classes } = this.props

        return (
            <form name="myForm" id="contactUsForm" onSubmit={this.onSubmit}>
                <Grid container spacing={3} justify="center">
                    <Grid item xs={12}>
                        <TextField onChange={this.onChange} required type="text" value={this.state.fullName} label="Full Name" id="fullName" name="fullName" classes={{ root: classes.root }}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField onChange={this.onChange} required type="text" value={this.state.jobTitle} label="Job Title" id="jobTitle" name="jobTitle" classes={{ root: classes.root }}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField onChange={this.onChange} required type="text" value={this.state.company} label="Company" id="company" name="company" classes={{ root: classes.root }}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField onChange={this.onChange} required type="email" value={this.state.email} label="E-Mail" id="email" name="email" classes={{ root: classes.root }}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField onChange={this.onChange} required type="text" value={this.state.subject} label="Subject" id="subject" name="subject" classes={{ root: classes.root }}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField onChange={this.onChange} required type="text" value={this.state.message} multiline={true} rows={5} label="Message" id="message" name="message" classes={{ root: classes.root }}/>
                    </Grid>
                    <Grid item xs={12}>
                    <Box mt={2} textAlign="center" fontSize="h4.fontSize" style={{ color: this.props.theme.palette.primary.main }}>
                        {this.state.successMessage}
                    </Box>
                    </Grid>
                    <Backdrop style={{ zIndex: this.props.theme.zIndex.drawer + 1, color: "#ffffff"}} open={this.state.openBackdrop}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <Grid item>
                        <Button variant="contained" color="primary" type="submit">
                            <Box py={1} px={3}>
                                <Typography variant="body1">Send</Typography>
                            </Box>
                        </Button>
                    </Grid>
                </Grid>
            </form>
        )
    }
}

export default ContactForm