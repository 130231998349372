import React from 'react'
import {
    Grid,
    Box,
    Dialog,
    Slide,
    Divider,
    Typography,
    makeStyles
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        height: 'calc(100vh - 30px)'
    },
    sectionHeader: {
        position: 'relative',
        paddingBottom: 20,
        marginBottom: 20,
        '&:after': {
            content: "''",
            position: 'absolute',
            width: 50,
            height: 5,
            backgroundColor: theme.palette.common.primaryBlue,
            bottom: 0,
            left: 0
        }
    },
    textAboveHeader: {
        color: theme.palette.grey[500],
        marginBottom: 5
    },
    sideImage: {
        width: '100%',
    },
    visitSiteButton: {
        color: '#FFFFFF',
        borderRadius: 3,
        backgroundColor: theme.palette.common.primaryBlue,
        padding: '15px 20px'
    }
}))

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function ISODialog (props) {
    
    const { onClose, selectedValue, open } = props;

    const handleClose = () => {
        onClose(selectedValue);
    };

    return(
        <Dialog TransitionComponent={Transition} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <Box>
                <img src={`${process.env.PUBLIC_URL}/images/registration.jpeg`} alt="registration.png" style={{
                    width: '100%',
                    height: 'auto'
                }}/>
            </Box>
        </Dialog>
    )
}

function LeftSide(props) {

    const classes = useStyles()
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
    };

    return(
        <Box px={{ xs: 3, md: 8 }} py={8} my={{ xs: 2, md: 8 }}>
            <Box className={classes.textAboveHeader}>

            </Box>
            <Box className={classes.sectionHeader} fontSize={24} fontWeight={600}>
                Security
            </Box>
            <Box className={classes.mainText} letterSpacing={1.3} lineHeight={2}>
                Healthcare providers and Patients across the world trust Connect2MyDoctor with the safe-keeping their clinical records every day. Security isn’t just tick in the box for us: it’s a core to every decision we make. We are the care takers of your data, but Healthcare providers and Patients remain the owner.
            </Box>
            <Box className={classes.mainText} letterSpacing={1.3} lineHeight={2} mt={3}>
            Healthcare providers can choose which country the data is stored in while fulfilling corporate policies and compliance requirements. Data regions currently available include: Sydney, Mumbai, Dubai. We work with Alibaba Cloud, Amazon Web Services and Microsoft Azure.
            </Box>
            <Box className={classes.mainText} letterSpacing={1.3} lineHeight={2} mt={3}>
                All data transfer is encrypted using HTTPS (end-to-end encryption). We use a 2048-bit SSL certification for encryption in transit. All data is also encrypted at rest, using the industry-standard AES-256 encryption algorithm.
            </Box>
            <Box className={classes.mainText} letterSpacing={1.3} lineHeight={2} mt={3}>
                Connect2MyDoctor is HIPAA and GDPR Compliant and ISO 27001 : 2013 certified (The highest level of global information security assurance available today)
            </Box>
            <Box mt={8}>
                <Grid container direction="row" justify="center" spacing={3}>
                    <Grid item>
                        <Box>
                            <img src={`${process.env.PUBLIC_URL}/images/hipaa.png`} alt="hipaa.png" style={{
                                height: 200,
                                width: 'auto'
                            }}/>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box pt={1}>
                            <img src={`${process.env.PUBLIC_URL}/images/iso.jpg`} alt="iso.png" style={{
                                    height: 185,
                                    width: 'auto',
                                    cursor: 'pointer'
                                }} onClick={handleClickOpen}/>
                            <Typography variant="subtitle1" align="center" style={{
                                    cursor: 'pointer'
                                }}  onClick={handleClickOpen}>Click to view</Typography>
                        </Box>
                    </Grid>
                    <ISODialog open={open} onClose={handleClose} />
                </Grid>
            </Box>
        </Box>
    )
}

function RightSide(props){

    return(
        <Box style={{
            overflow: 'hidden',
            height: '100%',
            width: '100%',
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/security.jpg)`,
            backgroundSize: 'cover',
            backgroundPositionX: '30%',
            minHeight: 500
        }}>
        </Box>
    )
}

function Security(props) {

    const [pageHeight, setPageHeight] = React.useState(window.innerHeight)

    React.useEffect(() => {
        changePageHeight()
    },[pageHeight])

    window.addEventListener('resize',() => {
        changePageHeight()
    })

    function changePageHeight(){
        setPageHeight(window.innerHeight - document.getElementById('navBar').clientHeight)
    }

    const { rightSide, leftSide, wrap, swap } = props

    return (
        <div id="security">
            <Grid container wrap={wrap} style={{
                minHeight: pageHeight
            }} >
                <Grid item md={6} sm={12} style={{ width: '100%' }}>
                    {
                        leftSide ? leftSide :
                        (
                            swap ? <RightSide/> : <LeftSide/>
                        )
                    }
                </Grid>
                <Grid item md={6} sm={12} style={{ width: '100%' }}>
                    {
                        rightSide ? rightSide : (
                            swap ? <LeftSide/> : <RightSide/>
                        )
                    }
                </Grid>
            </Grid>
            <Divider/>
        </div>
    )
}

export default Security
