import React, { Component } from 'react'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import { CssBaseline }  from '@material-ui/core'
import Intro from '../intro/Intro'
import NavBar from '../navbar/NavBar'
import C2md from '../c2md/C2md'
import ProductAndServices from '../productAndServices/ProductAndServices'
import Security from '../security/Security'
import Team from '../team/Team'
import Customers from '../customers/Customers'
import Contact from '../contact/Contact'
import Footer from '../footer/Footer'

const theme = createMuiTheme({
    typography: {
        fontFamily: 'Raleway',
    },
    palette:{
        primary: {
            main: '#0E83B8'
        },
        common: {
            primaryBlue: '#0E83B8',
            primaryGreen: '#9DC852',
            primaryGrey: '#8EA3B6',
            primaryRed: '#D75A4A',
            primaryBlack: '#4a4a4a',
            lightBlue: '#ECF4F8',
            darkBlue: '#374357',
            darkGrey: '#556D86',
            textColor: '#556D86',
            placeholder: '#8EA3B6',
            primaryBorder: '#556D86',
            focusBorder: '#0E83B8',
            linktextColor: '#0E83B8'
        }
    },
    overrides: {
        MuiCssBaseline: {
          '@global': {
            '*': {
              'scrollbar-width': '6px',
            },
            '*::-webkit-scrollbar': {
              width: '6px',
              height: '6px',
            },
            '*::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
            },
            '*::-webkit-scrollbar-thumb': {
                backgroundColor: '#374357',
            }
          }
        },
        MuiBackdrop: {
            root: {
                backgroundColor: 'rgb(34 42 56 / 90%)'
            }
        }
    }
})

class Home extends Component{
    
    render(){
        console.log(theme)
        return(
            <MuiThemeProvider theme={theme}>
                <CssBaseline>
                    <NavBar theme={theme}/>
                    <Intro title="Home" theme={theme}/>
                    <C2md title="Connect 2 MyDoctor" theme={theme}/>
                    <ProductAndServices wrap="wrap" id="test"/>
                    <Customers wrap="wrap"/>
                    <Team/>
                    <Security wrap="wrap-reverse" swap/>
                    <Contact wrap="wrap" swap  theme={theme}/>
                    <Footer />
                </CssBaseline>
            </MuiThemeProvider>
        )
    }
}

export default Home