import React from 'react'
import {
    Container,
    Grid,
    Box,
    Typography,
    Link,
    makeStyles,
    Icon
} from '@material-ui/core'
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

const useStyles = makeStyles((theme) =>({
    footer: {
        backgroundColor: theme.palette.common.darkBlue
    },
    linkTypo: {
        color: '#ffffff',
        opacity: 0.6,
        margin: '4px 0px',
        '&:hover':{
            opacity: 1,
            textDecoration: 'none'
        }
    },
    socialLink: {
        opacity: 0.6,
        '&:hover':{
            opacity: 1,
        }
    }
}))

function Footer(props){

    const classes = useStyles()

    return(
        <footer className={classes.footer}>
            <Container>
                <Grid container spacing={3} direction="row">
                    <Grid item md={4}>
                        <Grid container direction="column">
                            <Grid item>
                                <Box mb={{ xs: 2, md: 7 }}>
                                    <Typography variant="h5" style={{ fontWeight: 500 }}>
                                        Neev Tech Labs
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Grid container direction="column">
                                    <Typography variant="body2">
                                        &copy; {new Date().getFullYear()} Neev Tech Labs
                                    </Typography>
                                    <Typography variant="body2">
                                        All Rights Reserved
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={8}>
                        <Grid container direction="column" spacing={5}>
                            <Grid item>
                                <Box mb={0} fontWeight={500} fontSize={30} style={{ color : 'white' }}>
                                    #connectedcare
                                </Box>
                                <Box mt={0} lineHeight={1} fontWeight={300} fontSize={30} style={{ color : 'white' }}>
                                    made easy
                                </Box>
                                <Box mt={3} fontSize={20} fontWeight={500} style={{ color : 'white' }}>
                                    Telehealth | Remote Patient Monitoring
                                </Box>
                            </Grid>
                            <Grid item>
                                <Grid container direction="row" spacing={3} justify="space-between">
                                    <Grid sm={6} item>
                                        <Grid container direction="column" justify="center">
                                            <Typography variant="h6">
                                                Sitemap
                                            </Typography>
                                            <Link href="#home" className={classes.linkTypo}>
                                                Home
                                            </Link>
                                            <Link href="#connect2mydoctor" className={classes.linkTypo}>
                                                Connect2MyDoctor
                                            </Link>
                                            <Link href="#products" className={classes.linkTypo}>
                                                Products & Services
                                            </Link>
                                            <Link href="#security" className={classes.linkTypo}>
                                                Security
                                            </Link>
                                            <Link href="#team" className={classes.linkTypo}>
                                                Our Team
                                            </Link>
                                            <Link href="#customers" className={classes.linkTypo}>
                                                Customers
                                            </Link>
                                            <Link href="#contact" className={classes.linkTypo}>
                                                Contact Us
                                            </Link>
                                        </Grid>
                                    </Grid>
                                    <Grid sm={6} item>
                                        <Grid container direction="column">
                                            <Typography variant="h6">
                                                Social
                                            </Typography>
                                            <Link href="#" className={classes.socialLink}>
                                                <Icon>
                                                    <FacebookIcon style={{ color: 'red' }}/>
                                                </Icon>
                                            </Link>
                                            <Link href="#" className={classes.socialLink}>
                                                <Icon>
                                                    <TwitterIcon color="primary"/>
                                                </Icon>
                                            </Link>
                                            <Link href="#" className={classes.socialLink}>
                                                <Icon>
                                                    <LinkedInIcon/>
                                                </Icon>
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </footer>
    )
}

export default Footer