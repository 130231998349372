import React, { useEffect } from 'react'
import {
    Grid,
    Box,
    makeStyles,
    Link,
    IconButton,
    Dialog  
} from '@material-ui/core'
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import PageTemplate from '../pageTemplate/PageTemplate'
import c2mdLogo from '../../assets/c2mdlogoBlue.png'

const useStyles = makeStyles((theme) => ({
    playButtonContainer:{
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgb(34 42 56 / 30%)',
        '&:before': {
            content: "''",
            position: 'absolute',
            width: 110,
            height: 110,
            borderRadius: '50%',
            transform: 'translate(-50%,-50%)',
            animation: `$pulseAnimation 1s ${theme.transitions.easing.easeIn} infinite`,
            border: `0px solid ${theme.palette.common.primaryBlue}`,
            borderStyle: 'outset',
        }
    },
    "@keyframes pulseAnimation": {
        "0%": {
            opacity: 0.8,
            transform: 'scale(1)',
            border: `0px solid ${theme.palette.common.primaryBlue}`
            //boxShadow: '0px 0px 0px 0px white'
        },
        "100%": {
            opacity: 0,
            transform: 'scale(1.2)',
            border: `30px solid ${theme.palette.common.primaryBlue}`
            //boxShadow: '0px 0px 0px 0px white'
        }
    },
    playButton: {
        color: 'white',
        position: 'relative',
    },
    mainContainer: {
        backgroundColor: theme.palette.grey[200],
    },
    sectionHeader: {
        position: 'relative',
        paddingBottom: 20,
        marginBottom: 20,
        '&:after': {
            content: "''",
            position: 'absolute',
            width: 50,
            height: 5,
            backgroundColor: theme.palette.common.primaryBlue,
            bottom: 0,
            left: 0,
        }
    },
    textAboveHeader: {
        color: theme.palette.grey[500],
        marginBottom: 5
    },
    visitSiteButton: {
        color: '#FFFFFF',
        borderRadius: 3,
        backgroundColor: theme.palette.common.primaryBlue,
        padding: '15px 20px'
    }
}))

function C2md(props){
    
    const classes = useStyles()
    const [open,setOpen] = React.useState(false)
    const [videoWidth,setVideoWidth] = React.useState(640)
    const [videoHeight,setVideoHeight] = React.useState(360)

    const [responsiveSize,setPercentage] = React.useState(1)

    function setPercentageValue(){
        if(window.innerWidth > 900){
            setPercentage(1)
        }else{
            setPercentage(0.7)
        }
    }

    const openVideoModal = function(){
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    function setVideoWidthAndHeight() {
        var width = 800
        var height = ( width / 16 ) * 9
        if(window.innerWidth * 0.9 < 800){
            width = window.innerWidth * 0.9
            height = ( width / 16 ) * 9
        }
        setVideoWidth(width)
        setVideoHeight(height)
    }

    window.addEventListener('resize',() => {
        setVideoWidthAndHeight()
        setPercentageValue()
    })

    useEffect(() => {
        setVideoWidthAndHeight()
        setPercentageValue()
    }, [open])

    return(
        <div id="connect2mydoctor" className={classes.mainContainer}>
            <PageTemplate 
                leftSide={
                    <Box style={{ 
                            height: '100%',
                            backgroundImage: `url(${process.env.PUBLIC_URL}/images/c2mdSectionBg.jpg)`,
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            minHeight: 500,
                            position: 'relative',
                        }}>
                            <div className={classes.playButtonContainer}>
                                <IconButton className={classes.playButton} onClick={openVideoModal}>
                                    <PlayCircleFilledIcon style={{
                                            fontSize: 130
                                        }}/>
                                </IconButton>
                            </div>
                            <Dialog onClose={handleClose} open={open} maxWidth="md" disableScrollLock>
                                <Box>
                                    <iframe src="https://player.vimeo.com/video/477548401" width={videoWidth} height={videoHeight} frameBorder="0" allow="autoplay; fullscreen" ></iframe>
                                </Box>
                            </Dialog>
                    </Box>
                }
                rightSide={
                    <Grid container alignItems="center" style={{ height: '100%' }}>
                        <Grid item xs style={{ borderLeft: '1px solid rgba(0, 0, 0, 0.12)' }}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Grid container justify="flex-start">
                                        <Box px={5} mt={{ xs: 8, md: 0 }}>
                                            <img style={{ width: 400*responsiveSize }} src={`${process.env.PUBLIC_URL}/images/c2mdlogoBlue.png`} alt=""/>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Box p={5} pt={0} mt={3} mb={3}>
                                        <Box className={classes.textAboveHeader}>
                                            Overview 
                                        </Box>
                                        <Box className={classes.sectionHeader} fontSize={24} fontWeight={600}>
                                            Connect2MyDoctor
                                        </Box>
                                        <Box className={classes.mainText} letterSpacing={1.3} lineHeight={2} textAlign="justify">
                                            The COVID19 pandemic has made remote healthcare solutions go from a
                                            niche rarity to a preferred mode of care. Its ability to ensure physical distance
                                            and prevent the spread of infection among providers and patients has been
                                            invaluable.
                                            Connect2MyDoctor provides a comprehensive virtual platform to complement
                                            how people access and experience healthcare around the world. We are
                                            building a modern way of healthcare experience – one with greater
                                            convenience, impact and value.
                                        </Box>
                                        <Box textAlign="left" mt={8}>
                                            <Link href="https://www.connect2mydoctor.com" target="_blank" variant="button" className={classes.visitSiteButton}>
                                                Visit Website
                                            </Link>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                }
                wrap="wrap-reverse"/>
        </div>
    )
}

export default C2md