import React from 'react'
import {
    Grid,
    Box,
    makeStyles
} from '@material-ui/core'
import Slider from 'react-slick'
import '../../styles/clientLogoAnimation.scss'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { v4 as uuidv4 } from 'uuid'

const clientsLinks = {
    bethany: 'https://www.connect2mydoctor.com/bethany',
    bmh: 'https://www.connect2mydoctor.com/babymemorial',
    elitemission: 'https://www.connect2mydoctor.com/elitemission',
    meenakshi: 'https://www.connect2mydoctor.com/mm',
    meenakshi_madurai: 'https://www.connect2mydoctor.com/mmhrc',
    neurospinal: 'https://www.connect2mydoctor.com/nshdubai',
    nuhospitals: 'https://www.connect2mydoctor.com/nuhospitals',
    royalbahrain: 'https://www.connect2mydoctor.com/royalbahrainhospital',
    shalby: 'https://www.connect2mydoctor.com/shalby',
    lakeshore: 'https://www.connect2mydoctor.com/vpslakeshore',
    wassan: 'https://www.connect2mydoctor.com/wassandental',
    revive: 'https://www.connect2mydoctor.com/zainhospital',
}

function importClientLogos(r) {
    var elements = r.keys().map(r)
    elements.map(element => {
        Object.keys(clientsLinks).map( hospital => {
            if(new RegExp(hospital).test(element.default)){
                element.link = clientsLinks[hospital]
            }
            return element
        })
        return elements
    })
    return elements
}

const clientLogos = importClientLogos(require.context('../../assets/clients/transparentBG', false, /\.(png)$/))

const useStyles = makeStyles((theme) => ({
    greyBg: {
        backgroundColor: theme.palette.grey[200],
    },
    mainContainer: {
        height: 'calc(100vh - 30px)'
    },
    sectionHeader: {
        position: 'relative',
        paddingBottom: 20,
        marginBottom: 20,
        '&:after': {
            content: "''",
            position: 'absolute',
            width: 50,
            height: 5,
            backgroundColor: theme.palette.common.primaryBlue,
            bottom: 0,
            left: 0
        }
    },
    textAboveHeader: {
        color: theme.palette.grey[500],
        marginBottom: 5
    },
    sideImage: {
        width: '100%',
    },
    visitSiteButton: {
        color: '#FFFFFF',
        borderRadius: 3,
        backgroundColor: theme.palette.common.primaryBlue,
        padding: '15px 20px'
    },
    slideContainer:{
        minHeight: '330px',
        overflow: 'hidden',
        position: "relative",
        '&::after':{
            position: 'absolute',
            content: '""',
            width: '100%',
            height: '70px',
            left: '0',
            top: 0,
            zIndex: 999,
            backgroundImage: 'linear-gradient(180deg, rgba(255,255,255,1), rgba(255,255,255,1), rgba(255,255,255,0))',
        },
        '&:before':{
            position: 'absolute',
            display: 'block',
            content: '""',
            width: '100%',
            height: '70px',
            left: '0',
            bottom: 0,
            zIndex: 999,
            backgroundImage: 'linear-gradient(180deg, rgba(255,255,255,0), rgba(255,255,255,1), rgba(255,255,255,1))',
        }
    },
    logo: {
        maxWidth: 180,
        minWidth: 130,
        cursor: 'pointer',
        padding: '0 30px',
        margin: '20px 0',
        filter: 'drop-shadow(0px 0px 0px #222)',
        '-webkit-filter': 'drop-shadow(0px 0px 0px #222)'
    },
    flag: {
        height: 40
    },
    countryName: {
        fontWeight: 400,
        color: theme.palette.grey[600]
    }
}))

function LeftSide(props) {

    const classes = useStyles()

    return(
        <Box px={{ xs: 3, md: 8 }} py={8} my={{ xs: 2, md: 8 }}>
            <Box className={classes.textAboveHeader}>
                Our Customers
            </Box>
            <Box className={classes.sectionHeader} fontSize={24} fontWeight={600}>
                You’re in good company.
            </Box>
            <Box className={classes.mainText} letterSpacing={1.3} lineHeight={2}>
                Connect2MyDoctor today is used across multi and super speciality hospitals and medical centres across the globe. Since the start the platform has seen patients from over 17+ countries mainly using the platform for triage care, second opinion, consultation before travel and for follow up visits.
            </Box>
            <Box className={classes.textAboveHeader} my={3}>
                Current Deployments
            </Box>
            <Box mt={3}>
                <Grid container spacing={3} justify="flex-start">
                    <Grid item>
                        <Box textAlign="center">
                            <Box>
                                <img className={classes.flag} alt="indiaFlag" src={`${process.env.PUBLIC_URL}/images/flags/australia.png`} />
                            </Box>
                            <Box className={classes.countryName}>
                                Australia
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box textAlign="center">
                            <Box>
                                <img className={classes.flag} alt="indiaFlag" src={`${process.env.PUBLIC_URL}/images/flags/india.png`} />
                            </Box>
                            <Box className={classes.countryName}>
                                India
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box textAlign="center">
                            <Box>
                                <img className={classes.flag} alt="indiaFlag" src={`${process.env.PUBLIC_URL}/images/flags/oman.png`} />
                            </Box>
                            <Box className={classes.countryName}>
                                Oman
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box textAlign="center">
                            <Box>
                                <img className={classes.flag} alt="indiaFlag" src={`${process.env.PUBLIC_URL}/images/flags/uae.png`} />
                            </Box>
                            <Box className={classes.countryName}>
                                UAE
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box textAlign="center">
                            <Box>
                                 <img className={classes.flag} alt="indiaFlag" src={`${process.env.PUBLIC_URL}/images/flags/bahrain.png`} />
                            </Box>
                            <Box className={classes.countryName}>
                                Bahrain
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box textAlign="center">
                            <Box>
                                <img className={classes.flag} alt="indiaFlag" src={`${process.env.PUBLIC_URL}/images/flags/turkey.png`} />
                            </Box>
                            <Box className={classes.countryName}>
                                Turkey
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

function RightSide(props){

    const classes = useStyles()

    var settings = {
        infinite: true,
        speed: 10000,
        autoplaySpeed: 0,
        slidesToShow: 5,
        slidesToScroll: 1,
        slidesPerRow: 4,
        dots: false,
        arrows: false,
        autoplay: true,
        centerMode: true,
        pauseOnHover: true,
        cssEase: 'linear',
        vertical: true
    }

    return(
        <Box style={{
                overflow: 'hidden',
                width: '100%'
            }}>
            <Grid container justify="center" alignItems="center">
                <Grid item sm={12} className={classes.slideContainer} style={{textAlign: 'center'}}>
                    <Slider {...settings}>
                        {
                            clientLogos.map((logo) => {
                                return (
                                    <img onClick={() => {
                                            window.open(logo.link,'_blank')
                                        }}
                                        key={uuidv4()} 
                                        className={classes.logo} alt="img" src={logo}/>
                                )
                            })
                        }
                        {
                            clientLogos.map((logo) => {
                                return (
                                    <img onClick={() => {
                                            window.open(logo.link,'_blank')
                                        }}
                                        key={uuidv4()} 
                                        className={classes.logo} alt="img" src={logo}/>
                                )
                            })
                        }
                    </Slider>
                </Grid>
            </Grid>
        </Box>
    )
}

function Customers(props) {

    const { rightSide, leftSide, wrap, swap } = props
    const classes = useStyles()

    return (
        <div id="customers">
            <Grid container wrap={wrap} alignItems="center">
                <Grid item md={6} sm={12} className={classes.greyBg}>
                    {
                        leftSide ? leftSide :
                        (
                            swap ? <RightSide/> : <LeftSide/>
                        )
                    }
                </Grid>
                <Grid item md={6} sm={12}>
                    {
                        rightSide ? rightSide : (
                            swap ? <LeftSide/> : <RightSide/>
                        )
                    }
                </Grid>
            </Grid>
        </div>
    )
}

export default Customers
