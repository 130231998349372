import React, { useEffect } from 'react'
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Scrollspy from 'react-scrollspy'
import {
    Grid,
    Box,
    AppBar,
    Link,
    Hidden,
    Collapse,
    makeStyles
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    header: {
        backgroundColor: theme.palette.common.darkBlue
    },
    headerLogo: {
        color: theme.palette.primary.contrastText,
        fontSize: 22,
        fontWeight: 500,
        paddingLeft: 20,
        whiteSpace: 'nowrap'
    },
    linkTypo: {
        color: theme.palette.primary.contrastText,
        fontSize: 15,
        fontWeight: 500,
        padding: '20px 20px 15px 20px',
        borderBottom: '3px solid transparent',
        '&:hover': {
            borderBottom: `3px solid ${theme.palette.primary.contrastText}`
        },
        '&.active': {
            borderBottom: `3px solid ${theme.palette.primary.contrastText}`
        }
    }
}))

function NavBar(props){

    const classes = useStyles()

    const [ menuOpen,setOpenMenu ] = React.useState(true)
    const [ isMobileMenu, setIsMobileMenu ] = React.useState(false)

    window.addEventListener('resize', () => {
        showHideMenu()
    })

    function setOpen(isOpen){
        setOpenMenu(isOpen)
    }

    function showHideMenu(){
        if(window.innerWidth <= 959){
            setIsMobileMenu(true)
            setOpen(false)
        }else{
            setIsMobileMenu(false)
            setOpen(true)
        }
    }

    useEffect(() => {
        showHideMenu()
    }, [])

    return(
        <AppBar className={classes.header} elevation={2} id="navBar">
            <Grid container justify="space-between" alignItems="center">
                <Grid item>
                    <Box className={classes.headerLogo}>
                        Neev Tech Labs
                    </Box> 
                </Grid>
                <Grid item>
                    <Hidden mdUp>
                        <IconButton color="inherit" onClick={() => {
                            setOpen(!menuOpen)
                        }}>
                            <MenuIcon/>
                        </IconButton>
                    </Hidden>
                </Grid>
                <Grid item md xs={12}>
                    <Grid container justify="center">
                        <Grid item xs="auto">
                            <Collapse appear={true} in={menuOpen}>
                                <Scrollspy items={ ['home', 'connect2mydoctor', 'products', 'security', 'team', 'customers', 'contact'] } 
                                    style={{ 
                                        display: 'flex',
                                        margin: 0,
                                        flexFlow: isMobileMenu ? 'column' : 'row'
                                    }}
                                    currentClassName="active">
                                    <Link href="#home" underline="none" className={classes.linkTypo} onClick={() => {
                                            setOpen(!isMobileMenu)
                                        }}>
                                        Home
                                    </Link>
                                    <Link href="#connect2mydoctor" underline="none" className={classes.linkTypo} onClick={() => {
                                            setOpen(!isMobileMenu)
                                        }}>
                                        Connect2MyDoctor
                                    </Link>
                                    <Link href="#products" underline="none" className={classes.linkTypo} onClick={() => {
                                            setOpen(!isMobileMenu)
                                        }}>
                                        Products & Services
                                    </Link>
                                    <Link href="#security" underline="none" className={classes.linkTypo} onClick={() => {
                                            setOpen(!isMobileMenu)
                                        }}>
                                        Security
                                    </Link>
                                    <Link href="#team" underline="none" className={classes.linkTypo} onClick={() => {
                                            setOpen(!isMobileMenu)
                                        }}>
                                        Our Team
                                    </Link>
                                    <Link href="#customers" underline="none" className={classes.linkTypo} onClick={() => {
                                            setOpen(!isMobileMenu)
                                        }}>
                                        Customers
                                    </Link>
                                    <Link href="#contact" underline="none" className={classes.linkTypo} onClick={() => {
                                                setOpen(!isMobileMenu)
                                            }}>
                                            Contact Us
                                        </Link>
                                </Scrollspy>
                            </Collapse>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </AppBar>
    )

}

export default NavBar