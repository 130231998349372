import React from 'react'
import {
    Grid,
    Box,
    Link,
    makeStyles
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        height: 'calc(100vh - 30px)'
    },
    sectionHeader: {
        position: 'relative',
        paddingBottom: 20,
        marginBottom: 20,
        '&:after': {
            content: "''",
            position: 'absolute',
            width: 50,
            height: 5,
            backgroundColor: theme.palette.common.primaryBlue,
            bottom: 0,
            left: 0
        }
    },
    textAboveHeader: {
        color: theme.palette.grey[500],
        marginBottom: 5
    },
    sideImage: {
        width: '100%',
    },
    visitSiteButton: {
        color: '#FFFFFF',
        borderRadius: 3,
        backgroundColor: theme.palette.common.primaryBlue,
        padding: '15px 20px'
    }
}))

function LeftSide(props) {

    const classes = useStyles()

    return(
        <Box px={{ xs: 3, md: 8 }} py={8} my={{ xs: 2, md: 8 }}>
            <Box className={classes.textAboveHeader}>

            </Box>
            <Box className={classes.sectionHeader} fontSize={24} fontWeight={600}>
                Product/Service Details
            </Box>
            <Box className={classes.mainText} letterSpacing={1.3} lineHeight={2}>
                Connect2MyDoctor uses technology as the mode to deliver high quality
                telehealth care to patients. The compelling idea is simple: healthcare demand
                and supply are not matched. The current model insists that patients present
                in person to a location of the doctors choice or use nonclinical tools like
                WhatsApp video, FaceTime, Skype etc.
            </Box>
            <Box className={classes.mainText} letterSpacing={1.3} lineHeight={2} mt={3}>
                Connect2MyDoctor is a comprehensive virtual care platform that manages all the
                logistics in the care process.
            </Box>
            <Box textAlign="left" mt={8}>
                <Link href="/brochure.pdf" download="Connect2MyDoctor_Brochure.pdf"  variant="button" className={classes.visitSiteButton}>
                    Download Brochure
                </Link>
            </Box>
        </Box>
    )
}

function RightSide(props){

    return(
        <Box style={{
            overflow: 'hidden',
            height: '100%',
            width: '100%',
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/products.jpg)`,
            backgroundSize: 'cover',
            minHeight: 500
        }}>
        </Box>
    )
}

function ProductAndServices(props) {

    const [pageHeight, setPageHeight] = React.useState(window.innerHeight)

    React.useEffect(() => {
        changePageHeight()
    },[pageHeight])

    window.addEventListener('resize',() => {
        changePageHeight()
    })

    function changePageHeight(){
        setPageHeight(window.innerHeight - document.getElementById('navBar').clientHeight)
    }

    const { rightSide, leftSide, wrap, swap } = props

    return (
        <div id="products">
            <Grid container wrap={wrap} style={{
                minHeight: pageHeight
            }} >
                <Grid item md={6} sm={12} style={{ width: '100%' }}>
                    {
                        leftSide ? leftSide :
                        (
                            swap ? <RightSide/> : <LeftSide/>
                        )
                    }
                </Grid>
                <Grid item md={6} sm={12} style={{ width: '100%' }}>
                    {
                        rightSide ? rightSide : (
                            swap ? <LeftSide/> : <RightSide/>
                        )
                    }
                </Grid>
            </Grid>
        </div>
    )
}

export default ProductAndServices
