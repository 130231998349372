import React, { useEffect } from 'react'
import { 
    Container,
    Grid,
    Box,
    Link,
} from '@material-ui/core'
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

function Intro(props) {

    const [pageHeight, setPageHeight] = React.useState(window.innerHeight)
    const [responsiveFontSize,setFontSizePercentage] = React.useState(1)

    window.addEventListener('resize', () => {
        setFontSize()
    })

    useEffect(() => {
        setFontSize()
    }, [])

    function setFontSize(){
        if(window.innerWidth > 900){
            setFontSizePercentage(1)
        }else{
            setFontSizePercentage(0.6)
        }
    }

    React.useEffect(() => {
        changePageHeight()
    },[pageHeight])

    window.addEventListener('resize',() => {
        changePageHeight()
    })

    function changePageHeight(){
        if(window.innerWidth <= 900){
            setPageHeight(400)
        }else{
            setPageHeight(window.innerHeight - document.getElementById('navBar').clientHeight)
        }
    }
       
    return(
        <div id="home" style={{
                backgroundColor: props.theme.palette.common.darkBlue,
                backgroundImage: `url(${process.env.PUBLIC_URL}/images/heroBG_01.jpg)`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundBlendMode: 'multiply',
                backgroundPositionX: '40%',
                height: pageHeight,
                display: 'grid',
                alignItems: 'center'
            }}>
            
            <Container>
                <Grid container
                    direction="row"
                    alignItems="center">
                    <Grid item
                        sm={12}
                        container
                        style={{ 
                            marginTop: 90,
                            marginBottom: 90
                         }}>
                            <Box mb={10}>
                                <Box>
                                    <img src={`${process.env.PUBLIC_URL}/images/c2mdlogoBlue.png`} style={{ width: 300*responsiveFontSize, filter: 'brightness(20)' }}/>
                                </Box>
                                <Box mt={{ xs: 2, md: 3 }} mb={0} fontWeight={500} fontSize={55*responsiveFontSize} style={{ color : props.theme.palette.common.white }}>
                                    #connectedcare
                                </Box>
                                <Box mt={0} lineHeight={1} fontWeight={300} fontSize={55*responsiveFontSize} style={{ color : props.theme.palette.common.white }}>
                                    made easy
                                </Box>
                                <Box my={{ xs: 2, md: 3 }} mb={8} fontSize={20*responsiveFontSize} fontWeight={500} style={{ color : props.theme.palette.common.white }}>
                                    Telehealth | Remote Patient Monitoring
                                </Box>
                                <Box>
                                    <Grid container spacing={3}>
                                        <Grid item>
                                            <Link href="https://www.facebook.com/connect2mydoctor" target="_blank">
                                                <FacebookIcon fontSize="large" style={{ color : props.theme.palette.common.white }}/>
                                            </Link>
                                        </Grid>
                                        <Grid item>
                                            <Link href="https://www.linkedin.com/company/connect2mydoctor/" target="_blank">
                                                <LinkedInIcon fontSize="large" style={{ color : props.theme.palette.common.white }}/>
                                            </Link>
                                        </Grid>
                                        <Grid item>
                                            <Link href="https://twitter.com/connect2mydoc" target="_blank">
                                                <TwitterIcon fontSize="large" style={{ color : props.theme.palette.common.white }}/>
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

export default Intro