import React from 'react'
import {
    Grid,
    Box,
    Divider,
    makeStyles
} from '@material-ui/core'
import ContactForm from './ContactForm'

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        borderTop: `2px solid ${theme.palette.grey[200]}`
    },
    sectionHeader: {
        position: 'relative',
        paddingBottom: 20,
        marginBottom: 20,
        '&:after': {
            content: "''",
            position: 'absolute',
            width: 50,
            height: 5,
            backgroundColor: theme.palette.common.primaryBlue,
            bottom: 0,
            left: 0
        }
    },
    textAboveHeader: {
        color: theme.palette.grey[500],
        marginBottom: 5
    },
    sideImage: {
        width: '100%',
    },
    visitSiteButton: {
        color: '#FFFFFF',
        borderRadius: 3,
        backgroundColor: theme.palette.common.primaryBlue,
        padding: '15px 20px'
    },
    root: {
        background: theme.palette.common.white,
        width: '100%'
    },
}))

function LeftSide(props) {

    const classes = useStyles()

    return(
        <Box px={{ xs: 3, md: 8 }} py={8} my={{ xs: 2, md: 8 }}>
            <ContactForm classes={classes} theme={props.theme}/>
        </Box>
    )
}

function RightSide(props){

    const classes = useStyles()

    return(
        <Box style={{
            overflow: 'hidden',
            height: '100%',
            width: '100%',
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/contactUsBG.png)`,
            backgroundPositionY: '100%',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain'
        }}>
            <Box style={{
                    display: 'flex',
                    height: '100%',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                    flexFlow: 'column',
                    justifyContent: 'flex-start',
                    minHeight: 500
            }} px={{ xs: 3, md: 8 }} py={8} my={{ xs: 2, md: 8 }}>
                <Grid container>
                    <Grid item xs={12} sm={5}>
                        <Box className={classes.sectionHeader} fontSize={24} fontWeight={600}>
                            Contact Us
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={7}>
                        <Grid container justify="flex-end">
                            <Grid item xs="auto">
                                <Box textAlign="right">
                                    <Box fontSize={25} mb={2} fontWeight={600}>
                                        Australia
                                        <Divider/>
                                    </Box>
                                    <Box fontSize={16} mb={1} fontWeight={500}>
                                        Neev Tech Labs Pty. Ltd.
                                    </Box>
                                    <Box fontSize={16} mb={1} fontWeight={400}>
                                        67 / 135 Cardigan Street, Carlton,
                                    </Box>
                                    <Box fontSize={16} mb={1} fontWeight={400}>
                                        Melbourne 3053, Victoria
                                    </Box>
                                    <Box fontSize={16} mb={1} fontWeight={400}>
                                        Australia
                                    </Box>
                                    <Box fontSize={16} mb={1} fontWeight={400}>
                                        howdy@connect2my.doctor
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs="auto">
                                <Box textAlign="right" mb={3}>
                                    <Box fontSize={25} mb={2} fontWeight={600}>
                                        India                                    
                                        <Divider/>
                                    </Box>
                                    <Box fontSize={16} mb={1} fontWeight={500}>
                                        Neev Labs IT Solutions Pvt. Ltd.
                                    </Box>
                                    <Box fontSize={16} mb={1} fontWeight={400}>
                                        #222, Indiranagar Double Road
                                    </Box>
                                    <Box fontSize={16} mb={1} fontWeight={400}>
                                        Domlur II Stage, Indiranagar
                                    </Box>
                                    <Box fontSize={16} mb={1} fontWeight={400}>
                                        Bangalore, Karnataka-560071
                                    </Box>
                                    <Box fontSize={16} mb={1} fontWeight={400}>
                                        namaste@connect2my.doctor
                                    </Box>
                                </Box>
                            </Grid>                
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

function Contact(props) {

    const [pageHeight, setPageHeight] = React.useState(window.innerHeight)
    const classes = useStyles()

    React.useEffect(() => {
        changePageHeight()
    },[pageHeight])

    window.addEventListener('resize',() => {
        changePageHeight()
    })

    function changePageHeight(){
        setPageHeight(window.innerHeight - document.getElementById('navBar').clientHeight)
    }

    const { rightSide, leftSide, wrap, swap } = props

    return (
        <div className={classes.mainContainer} id="contact">
            <Grid container wrap={wrap}>
                <Grid item md={6} sm={12} style={{ width: '100%' }}>
                    {
                        leftSide ? leftSide :
                        (
                            swap ? <RightSide/> : <LeftSide theme={props.theme}/>
                        )
                    }
                </Grid>
                <Grid item md={6} sm={12} style={{ width: '100%' }}>
                    {
                        rightSide ? rightSide : (
                            swap ? <LeftSide theme={props.theme}/> : <RightSide/>
                        )
                    }
                </Grid>
            </Grid>
        </div>
    )
}

export default Contact
