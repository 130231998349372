import React from 'react'
import {
    Box,
    Grid,
    Grow,
    Dialog,
    Link,
    Divider,
    makeStyles
} from '@material-ui/core'
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

const useStyles = makeStyles((theme) => ({
    header: {
        position: 'relative',
        '&:after': {
            content: "''",
            position: 'absolute',
            width: 50,
            height: 5,
            backgroundColor: '#FF0000',
            bottom: 0,
            left: '50%',
            transform: 'translateX(-50%)'
        }
    },
    teamItem: {
        cursor: 'pointer',
        transition: '350ms',
        '&:hover': {
            transform: 'scale(1.1)'
        }
    },
    partner: {
        transition: '350ms',
        '&:hover': {
            transform: 'scale(1.1)'
        }
    },
    temaItemImageContainer: {
        width: '100%',
        backgroundSize: 'cover',
        borderRadius: 5,
        overflow: 'hidden',
    },
    teamName: {
        fontWeight: 500,
        marginBottom: 5,
        color: theme.palette.grey[700]
    },
    teamPosition: {
        fontWeight: 600,
        color: theme.palette.grey[500]
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}))

const leadershipTeam = [
    {
        image: "images/team/pramod.png",
        name: "Pramod Kutty",
        position: "Co Founder and CEO",
        linkedin: "https://www.linkedin.com/in/pramodkutty/",
        details: [
            "Pramod is an experienced New Product Development Manager and Technology entrepreneur. Pramod fulfilled the responsibility of launching new products across multi channels and geographies. His core strengths have been New Product Development, Product Evangelist, Business Development, Team building and Cross functional alignment. Key areas: Mobile, Internet, Digital Health.",
            "At Neev Tech, Pramod bootstrapped a world-class telehealth platform ground up and engaged with some of the largest multi-specialty hospitals in India and the Middle East. Prior to founding Connect2MyDoctor, Pramod has assisted clients in Healthcare, Media and Beverages domain across Melbourne and Dubai to launch products in Web and Mobile.",
            "Before relocating to Melbourne, Pramod worked at Omnicom Media Group (NYSE: OMC) Dubai as a Business Manager - Mobility. He managed the entire responsibility to launch the business unit, build the practice, recruit and train the team and lead the business development. The business unit went on to create several award-winning products for some of the largest clients in the network during his time.",
            "Prior to Omnicom, Pramod was hired to launch the Asia and Middle East business at July Systems (Now part of Cisco) as Sr. Program Manager. He worked with major telecom and media companies in the region to help them go mobile. He was responsible for overall business management, marketing, revenue maximization and relationship management functions for the allocated region. He also played an active role in launching IPL (India’s most popular Sports league) on Mobile which was selected as 1st place for the “Mobile International” category at the 2010 Mobile Excellence Awards. Other finalists included Conde Nast, EA Mobile, and Warner Bros.",
            "Prior to July Systems, as one of the first Product Managers of OnMobile, Pramod joined as an Account Manager and later went ahead to head the Data and Messaging business."
        ]
    },
    {
        image: "images/team/nelby.png",
        name: "Nelby Joseph",
        position: "Co Founder",
        details: [
            "Nelby is the Co-Founder at Neev Tech Labs and is responsible for platform development and delivery. Over the last 20 years he has worked in Banking, Financial Services and Insurance Industries handling application development and IT Infrastructure in leadership roles. He has worked on numerous projects as security specialist providing inputs towards adhering to secure software development methodologies. He has provided technology leadership by the way of executing technology strategies and managing large innovative projects successfully. He has also set up data centres across geographies by employing cost effective practices.",
            "Prior to Neev Labs, Nelby worked in leadership roles including Yalamanchili and Visa Inc in Singapore."
        ]
    },
    {
        image: "images/team/wright.png",
        name: "David Wright",
        linkedin: "https://www.linkedin.com/in/davidwrightmc/",
        position: "Director of Strategy",
        details: [
            "Originally trained as a teacher London (England). Gained a B.Ed (London), MHMS (Qual) from University of Queensland and an MBA from Queensland University of Technology focusing on health care systems. He has undertaken Executive leadership and governance programs at Harvard and Oxford Universities.",
            "David has over 30 years’ experience as both a CEO (Brisbane North Division of General Practice, Australian General Practice Accreditation Ltd (AGPAL) Quality in Practice (QIP), the Royal Australian College of General Practitioners and as a Director of Australian Medicines Handbook, and the Improvement Foundation.",
            "He is the founding (2005) Chairman of the Improvement Foundation assisting in driving successful quality improvement initiatives at provider level including, over 1500 health services, 320 Aboriginal Health Services, Primary Health Networks and State Government bodies.",
            "David has considerable international consulting experience including in the UAE, Bahrain, Kingdom of Saudi Arabia, New Zealand, UK, and Ireland. His consulting work has included governance reviews, strategic planning, organisational restructures and business re-engineering for clients including Australian Psychological Society (APS), Relationships Australia, Allied Health Professionals Association (AHPA), Eastern Province Kingdom of Saudi Arabia. He was Director of Health for Bahrain Economic Development Board and Director of Quality for Bahrain Defence Force Medical Services.",
            "David is a designated expert evaluator on health programs for the European Commission based in Brussels and also a member of the Expert Group on quality improvement of health systems with the International Society for Quality in Healthcare (ISQua). In 2015 he undertook consulting with the WHO and UN with developing countries."
        ]
    },
    {
        image: "images/team/nitin.png",
        name: "Nitin Mohandas",
        linkedin: "https://www.linkedin.com/in/nitinmo/",
        position: "Director of Partnerships",
        details: [
            "Nitin is a Civil Engineering Graduate with a Master’s in Business. This mix has taught him the basics of having a strong foundation to ramp up a business. With 25+ years of experience, he has used been building sales for multiple organizations across industries, and thus building business relationships across the globe.",
            "At Neev Tech, Nitin leads conversations with healthcare providers & ancillary service providers. Finding the appropriate synergy & commercial models that work for both parties for a win-win scenario, is what he does best.",
            "Prior to this, he used to travel across the globe setting up business units for VFS Global, who annually service 25 Million customers in 90+ countries. He & his team were directly responsible for ensuring that each customer has access to conveniently purchase ancillary travel services for a trouble-free trip. This was a start-up within an established company, and he built it up from scratch.",
            "To become a leader, you need to work yourself up the ladder, and this is what he accomplished as Head of Samsung Mobile in the GCC. Starting at #4, he built the customer engagement models and retail marketing programs to ensure that Samsung Mobile became #1, before he moved on whilst achieving the magic sales number of USD 1 Billion.",
            "His sales skills were honed working with majors such as Heineken and P&G, where the essence of using customer data analytics to reap customer satisfaction and the power of building relationships with your customer were strengthened."
        ]
    }
]

const advisors = [
    {
        image: "images/team/mcgorry.png",
        name: "Prof. Patrick McGorry",
        linkedin: "https://www.linkedin.com/in/patrick-mcgorry-a819698/",
        details: [
            "Pat is a psychiatrist known world-wide for his development and scaling up of early intervention and youth mental health services and for mental health innovation, advocacy and reform.",
            "He is Professor of Youth Mental Health at the University of Melbourne and founding editor of the journal Early Intervention in Psychiatry.  He led the advocacy which resulted in the establishment by the Australian Government in 2005 of the National Youth Mental Health Foundation, which in 2006 became headspace and he remains a founding board member of that organisation. He has played a key advocacy and advisory role to government and health system reform in Australia and in many parts of the world. He has published over 980 publications, with 56810 citations.",
            "He is President of the International Association for Youth Mental Health, Past-President of the Schizophrenia International Research Society, Past-President of the Society for Mental Health Research, and was Founding President and is now Treasurer of the IEPA: Early Intervention in Mental Health.",
            "Professor McGorry has been the recipient of many awards including: Australian of the Year 2010,  and became an Officer of the Order of Australia, the Annual Research Award from the National Alliance for the Mentally Ill in Washington DC, the Lieber Prize for Schizophrenia Research by the US-based Brain and Behaviour Foundation.  In 2016 he became the first psychiatrist to be elected as a Fellow of the Australian Academy of Science."
        ]
    },
    {
        image: "images/team/philip.png",
        name: "Philip Davies",
        linkedin: "https://www.linkedin.com/in/philkdav/",
        details: [
            "Philip has extensive experience in health policy and governance. During an international career spanning more than 40 years he has held senior positions in four government health administrations, including as Permanent Secretary (CEO) of the Fiji Government Ministry of Health and Medical Services. He has also served as Professor of Health Systems and Policy in a leading Australian University, worked as a Senior Health Economist in the global headquarters of the World Health Organisation and was appointed as a Partner in a ‘Big 4’ international professional services firm.",
            "He has undertaken consultancies in more than 20 countries in Africa, Asia and the Pacific region for clients including the Australian Department of Foreign Affairs and Trade, the UK Department for International Development, World Bank and World Health Organisation.",
            "His previous non-executive roles include Deputy Chair of the World Health Organisation’s Executive Board (its principal governing body) and Director of a number of government agencies and not-for-profit companies operating in the health sector."
        ]
    },
    {
        image: "images/team/sajimathew.png",
        name: "Saji Mathew",
        details: [
            "Passionate about people, technology and operations, Saji is responsible for the entire gamut of Healthcare operations, talent and IT initiatives at Baby Memorial Hospital, Kerala. Saji has around 27 years of consulting and management experience and has worked with leading global IT and healthcare organisations. Prior to joining Baby Memorial Hospital, Saji worked with UST Global, Monetary Authority of Singapore, Cap Gemini and Tata Consultancy Services in India, Singapore, Australia, Europe and North America.",
            "He holds a Master’s degree in Software Engineering from National University of Singapore and Digital Electronics from Cochin University of Science and Technology. He achieved the first rank in Electrical and Electronics Engineering (University of Kerala) in 1991.",
            "Saji has received many awards including: Healthcare honours in digital innovation at CIO Crown summit in August 2019.   “Most Influential HR Leaders in India” title by World HRD Congress in 2017.  He was  also recognised as one among 50 South India’s most talented HR leaders by the Asia Pacific HRD congress in Sept 2017. Under his leadership the organisation bagged the AHPI “Best Hospital to Work for” award in 2019, “Kaizen Implementation” award from Quality Council of India in 2018, Express Healthcare Excellence award in Best IT enabled process in June 2019 and Digital Adoption Platform award from “Walkme” in July 2019.",
        ]
    },
    {
        image: "images/team/brownie.jpg",
        name: "Prof. Sharon Brownie", 
        details: [
            "Professor Sharon Brownie is Professor Sharon Brownie RN, RM, BEd, MEd Admin, M Hth S Mgt, MA Mgt (N), GAICD, FCNA, DBA a capable health and social service executive with extensive management, leadership and policy development experience. She has extensive experience across the health, education, economic development and employment sectors. Her global perspective have developed through her work in Australia, New Zealand, East Africa, Fiji and the Middle East. Her leadership roles have included significant capacity building, workforce development,business growthand change management mandates. While in these roles she has actively maintained practice and nursing licensure in both Australia and New Zealand.",
            "Sharon has a strong background in community engagement with proven expertise in working in partnership with professional entities, industry stakeholders and government both nationally and internationally. She has consistently demonstrated skills in both strategic engagement and the detailed complexities of day-to-day operational management.",
            "She is passionate in her belief that quality health and education services should be available to everyone irrespective of their location or socioeconomic circumstances. Thus, she has worked in various rural and remote contexts improving service quality and access for marginalized and disadvantaged people. She excels in roles with a stated strategic intent to build strong and sustainable social, educational and economic conditions that improve community wellbeing and enable populations, communities and families to thrive and grow."
        ]
    },
    {
        image: "images/team/zena.png",
        name: "Dr Zena Burgess, PhD",
        linkedin: "https://www.linkedin.com/in/zena-burgess-b8991a21/",
        details: [
            "Dr. Zena Burgess PhD has a doctorate in clinical and organisational psychology and Master of Business Administration, and Education. She is a Fellow of the Australian Psychological Society and the Australian Institute of Company Directors and has worked in health for over two decades, delivering front line services to both regional and urban communities.",
            "For over a decade she led the Royal Australian College of General Practitioners (RACGP) as CEO during a period of growth and professional recognition as a patient-focused organisation contributing to health reform, standard setting, and innovation in primary care.",
            "Dr. Burgess has a reputation for leadership during periods of change and is committed to health equity through a social determinants framework. She has been a member of the Victorian Civil and Administrative Appeals Tribunal (VCAT), the Disability Review Board and the Psychologists Registration Board and is currently a Director on the Australian Patients Association.",
            "Dr. Burgess is currently CEO of the Australian Psychologist Society, a membership organisation representing 24,000 psychologists in both public and private sectors."
        ]
    },
    {
        image: "images/team/ravikannan.png",
        name: "Dr. R Ravi Kannan",
        details: [
            "Dr Ravi Kannan is a surgical oncologist from Chennai who moved to Assam in 2007 to develop the Cachar Cancer Hospital and Research Centre in Silchar, in the Barack valley of Assam. The hospital has treated over 70,000 cancer patients free of cost. He had left his practice in the Adyar Cancer Institute in Chennai, where after 15 years treating patients. On Indian Republic Day 2020, he was awarded the prestigious Padma Shri, which is the fourth highest award in the Republic of India for his services to the community.",
            "Before he transformed healthcare in the valley, their nearest hospital was 350 kilometres away. The Cachar Cancer Hospital now offers additional services to assist in supporting cancer patients, including opportunities to work as support assistants via an employment agency and support with food and accommodation.",
            "Quote following a Ted Talk by Dr Ravi. “These are the type stories that make me believe that humanity still exists. A brilliant initiative and a humble and inspirational human. Barak Valley and perhaps the entire medical fraternity of India is blessed to have people like him”"
        ]
    }
]

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Grow timeout={250} ref={ref} {...props} />;
});

function MemberDetailDialog (props) {
    
    const classes = useStyles()
    const { onClose, selectedValue, open, member } = props;

    const handleClose = () => {
        onClose(selectedValue);
    };

    return(
        <Dialog TransitionComponent={Transition} onClose={handleClose} 
            aria-labelledby="simple-dialog-title"
            maxWidth="md"
            open={open} disableScrollLock>
            <MuiDialogTitle disableTypography className={classes.root}>
                <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </MuiDialogTitle>
            <MuiDialogContent>
                <Grid container>
                    <Grid item xs={12}>
                        <Box style={{ 
                                width: 90, height: 90, 
                                borderRadius: '50%', 
                                overflow: 'hidden', 
                                backgroundImage: `url(${process.env.PUBLIC_URL}/${member.image})`,
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                filter: 'grayscale(1)'
                            }} mt={3}>
                        </Box>
                        <Box textAlign="center" className={classes.teamName} px={2} mt={1}>
                            {member.name}
                        </Box>
                        <Box textAlign="center" className={classes.teamPosition} mb={2}>
                            {member.position}
                        </Box>
                        {
                            member.linkedin ? 
                            <Box textAlign="center" className={classes.teamPosition} mb={2}>
                                <Link href={member.linkedin} target="_blank">
                                    <LinkedInIcon fontSize="large" />
                                </Link>
                            </Box> : null
                        }
                    </Grid>
                    <Grid item xs={12}>
                        {
                            member.details.map((details,index) => {
                                return (
                                    <Box textAlign="center" lineHeight={2} mb={2} key={`${member.name}_${index}`}>
                                        {details}
                                    </Box>
                                )
                            })
                        }
                    </Grid>
                </Grid>
            </MuiDialogContent>
        </Dialog>
    )
}

function TeamMember(props){
    
    const classes = useStyles()
    const { member } = props

    const [open, setOpen] = React.useState(false);
    const [imageSize,setSize] = React.useState(200)

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
    };

    React.useEffect(() => {
        setImageSize()
    },[])

    window.addEventListener('resize', () => {
        setImageSize()
    })

    function setImageSize(){
        if(window.innerWidth > 490){
            setSize(200)
        }else{
            setSize(130)
        }
    }

    return(
        <Grid item>
            <Box className={classes.teamItem} style={{ width: imageSize }} onClick={handleClickOpen}>
                <Box className={classes.temaItemImageContainer} style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/${member.image})`,
                    filter: 'grayscale(1)',
                    height: imageSize
                }}></Box>
                <Box textAlign="center" fontSize={{ xs: 16, md: 20 }} className={classes.teamName} px={2} mt={1}>
                    {member.name}
                </Box>
                <Box textAlign="center" fontSize={{ xs: 10, md: 12 }} className={classes.teamPosition} mb={2}>
                    {member.position}
                </Box>
            </Box>
            <MemberDetailDialog open={open} onClose={handleClose} member={member}/>
        </Grid>
    )
}

function Team() {
    
    const classes = useStyles()

    return (
        <div className={classes.mainContainer} id="team">
            <Box px={{ xs: 3, md: 8 }} py={{ xs: 2, md: 8 }} my={{ xs: 2, md: 8 }}>
                <Box className={classes.header} fontSize={24} fontWeight={600} textAlign="center" pt={5} pb={3} mt={{ xs: 2, md: 5 }}>
                    Meet the Team
                </Box>
                <Box className={classes.subHead} fontSize={20} fontWeight={500} textAlign="center" my={5}>
                    Leadership Team
                </Box>
                <Box marginLeft="auto" marginRight="auto">
                    <Grid container justify="center" spacing={5}>
                        {
                            leadershipTeam.map(member => {
                                return(
                                    <TeamMember member={member} key={member.name}/>
                                )
                            })
                        }
                    </Grid>
                </Box>
                <Box className={classes.subHead} fontSize={20} fontWeight={500} textAlign="center" my={5}>
                    Our Advisors
                </Box>
                <Box marginLeft="auto" marginRight="auto">
                    <Grid container justify="center" spacing={5}>
                        {
                            advisors.map(member => {
                                return(
                                    <TeamMember member={member} key={member.name}/>
                                )
                            })
                        }
                    </Grid>
                </Box>
                <Box className={classes.subHead} fontSize={20} fontWeight={500} textAlign="center" my={5}>
                    Our Partners
                </Box>
                <Box marginLeft="auto" marginRight="auto" mb={{ xs: 2, md: 5 }}>
                    <Grid container justify="center" spacing={5}>
                        <Grid item>
                            <Link href="https://www.artesianinvest.com/" target="_blank" style={{ textDecoration: 'none' }}>
                                <Box className={classes.partner}>
                                    <Box style={{
                                            backgroundColor: '#FFFFFF',
                                            padding: 10,
                                            borderRadius: 5,
                                            overflow: 'hidden',
                                            marginLeft: 'auto',
                                            marginLeft: 'auto',
                                            marginBottom: 20
                                        }} width={{ xs: 200, md: 300}}>
                                            <img src={`${process.env.PUBLIC_URL}/images/artesian.webp`} alt="artesian" style={{ width: '100%' }}/>
                                    </Box>
                                    <Grid container justify="center" alignContent="center">
                                        <Grid item>
                                            <Box textAlign="center" className={classes.teamName} px={2} mt={1}>
                                                Artesian
                                            </Box>
                                            <Box textAlign="center" className={classes.teamPosition} mb={2}>
                                                Investor
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="https://medtechactuator.com/" target="_blank" style={{ textDecoration: 'none' }}>
                                <Box className={classes.partner}>
                                    <Box style={{
                                            backgroundColor: '#FFFFFF',
                                            padding: 10,
                                            borderRadius: 5,
                                            overflow: 'hidden',
                                            marginLeft: 'auto',
                                            marginLeft: 'auto'
                                        }} width={{ xs: 200, md: 300}}>
                                            <img src={`${process.env.PUBLIC_URL}/images/medtech_black.png `}alt="medtech" style={{ width: '100%' }}/>
                                    </Box>
                                    <Grid container justify="center" alignContent="center">
                                        <Grid item>
                                            <Box textAlign="center" className={classes.teamName} px={2} mt={1}>
                                                Medtech Actuator
                                            </Box>
                                            <Box textAlign="center" className={classes.teamPosition} mb={2}>
                                                Part of Oct ’19-Sep ’20 Cohort
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="https://www.ahpi.in/" target="_blank" style={{ textDecoration: 'none' }}>
                                <Box className={classes.partner}>
                                    <Box style={{
                                            backgroundColor: '#FFFFFF',
                                            padding: 10,
                                            borderRadius: 5,
                                            overflow: 'hidden',
                                            marginLeft: 'auto',
                                            marginLeft: 'auto'
                                        }} width={{ xs: 200, md: 200}}>
                                            <img src={`${process.env.PUBLIC_URL}/images/ahpi.png`} alt="ahpi" style={{ width: '100%' }}/>
                                    </Box>
                                    <Grid container justify="center" alignContent="center">
                                        <Grid item>
                                            <Box textAlign="center" className={classes.teamName} px={2} mt={1}>
                                                AHPI  
                                            </Box>
                                            <Box textAlign="center" className={classes.teamPosition} mb={2}>
                                                Official Telehealth Partner
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Divider/>
        </div>
    )
}

export default Team
