import mailgun from 'mailgun-js'

const api_key = 'e4cff7a790b84f9d26263026fe2e1221-203ef6d0-863c735f'
const domain = 'sandbox4a62e7b4622c4f4cbeccfaecbccee90f.mailgun.org'

const mg = mailgun({
    apiKey: api_key,
    domain: domain
})

export default mg